import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'my-fuel-lib/lib/models/transaction.model';

@Component({
  selector: 'app-site-transaction-list',
  templateUrl: './site-transaction-list.component.html',
  styleUrls: ['./site-transaction-list.component.scss']
})
export class SiteTransactionListComponent implements OnInit {

  @Input() transactions: Transaction[];
  public columnsToDisplay = ['pump', 'grade', 'amount', 'endTime'];

  constructor() {}

  ngOnInit() {
  }
}
