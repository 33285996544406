import { Component, OnInit, Input } from '@angular/core';
import { GradePrice } from 'my-fuel-lib/lib/models/grade.model';

@Component({
  selector: 'app-site-grade-price-list',
  templateUrl: './site-grade-price-list.component.html',
  styleUrls: ['./site-grade-price-list.component.scss']
})
export class SiteGradePriceListComponent implements OnInit {
  @Input() public grades: GradePrice[];
  @Input() public title: string;
  @Input() public mode: string;
  @Input() public activateAt: string;

  columnsToDisplay = ['product'];

  constructor() { }

  ngOnInit() {
    switch (this.mode) {
      case '1':
        this.columnsToDisplay.push('officialPrice');
        break;
      case '2':
        this.columnsToDisplay.push('presetPrice');
        break;
      default:
        this.columnsToDisplay.push('price');
        break;
    }
  }

}
