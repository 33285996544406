import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

import { Site } from '../_models/site.model';
import { AuthService } from './auth.service';
import { mergeMap, map, take } from 'rxjs/operators';
import { Connector } from './../_models/connector.model';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Transaction } from 'my-fuel-lib/lib/models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions) {
  }

 getUsers() {
    return this.db.collection('users').valueChanges().pipe(
      map(users => {
        users.forEach(user => {
          // @ts-ignore
          this.db.doc(`groups/${user.group}`).get().subscribe(group =>
            // @ts-ignore
            user.group = group.data()
          );
        });
        return users;
      })
    );
  }

  getGroups() {
    return this.db.collection('groups').valueChanges().pipe(
      map(groups => {
        groups.forEach(group => {
           // @ts-ignore

          group.siteInfo = [];
          // @ts-ignore
          group.sites.forEach(site => {
            this.db.doc(`sites/${site}`).get().subscribe(siteInfo => {
               // @ts-ignore
              group.siteInfo.push(siteInfo.data());
            }, error => console.log(error));
          });
        });
        return groups;
      })
    );
  }

  addUser(user) {
    this.db.collection('users').add(user);
  }

  updateUser(user) {
    this.db.doc(`users/${user.id}`).update(user);
  }
}
