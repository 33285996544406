import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Site } from 'src/app/_models/site.model';
import { SiteService } from 'src/app/_services/site.service';
import { Transaction } from 'projects/my-fuel-lib/src/lib/models/transaction.model';
import { PumpEventTypes } from 'projects/my-fuel-lib/src/lib/models/pump-event.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/_services/auth.service';
import { tap, take } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { CodeDialogComponent } from './../code-dialog/code-dialog.component';
import { SitePriceUpdateDialogComponent } from './../site-price-update-dialog/site-price-update-dialog.component';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit, OnDestroy {

  private siteId: string;
  public site: Observable<Site>;
  private connectorSub: Subscription;
  public transactions: Observable<Transaction[]>;
  public page = 'dispensers';
  private user;
  private userSub;
  private grades;
  private siteSub;

  constructor(private route: ActivatedRoute,
    private siteService: SiteService,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    public dialog: MatDialog
    ) {
    this.route.params.subscribe(params => this.siteId = params.id);
    this.site = siteService.getSiteListener(this.siteId);

    this.transactions = siteService.getSiteTransactionListener(this.siteId);

    this.connectorSub =
      this.siteService.getConnectorListener(this.siteId)
      .subscribe(connector => {
        if (connector.state === 'IDLE') {
          siteService.setConnected(this.siteId);
        }
      });

    this.siteSub = this.siteService.getSiteListener(this.siteId).pipe(
      tap(site => this.grades = site.data.grades),
      tap(site => {
        if (site.activity) { this.snackBar.dismiss(); }
        if (site.activityDetails) {
          this.snackBar.open(site.activityDetails, '', {
            panelClass: 'snack-bar-error',
            duration: 5000,
        });
      }
      })
    ).subscribe();

    this.userSub = this.authService.getUserListener().subscribe(
      user => this.user = user
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.connectorSub.unsubscribe();
    this.siteSub.unsubscribe();
  }

  navigateTo(pageName: string) {
    this.page = pageName;
  }

  pumpEventHandler($event) {
    if (this.user.role === 'user') {
      this.snackBar.open('Not authorized to perform this action', '', {
        duration: 5000,
      });

      return;
    }

    const lastAuth = DateTime.fromJSDate(this.user.lastAuth.toDate());

    switch ($event.type) {
      case PumpEventTypes.DisableClicked: {
          if (this.user.role === 'watchman' || this.user.role === 'administrator') {
            if (lastAuth.plus({minutes: 10}) < DateTime.utc()) {

              this.authService.request2Factor().subscribe();
              const ref = this.dialog.open(CodeDialogComponent);
                ref.afterClosed().subscribe(result => {
                  this.authService.verify2Factor(result).subscribe(authState => {
                      this.snackBar.open('Disabling dispenser');
                      this.siteService.sendPumpDisable(this.siteId, $event.pumpId);
                  });
                });

                return;
            }

            this.snackBar.open('Disabling dispenser');
            this.siteService.sendPumpDisable(this.siteId, $event.pumpId);
            return;
          }


            this.snackBar.open('Not authorized to perform this action', '', {
              duration: 5000,
            });

          break;
        }

        case PumpEventTypes.EnableClicked: {
          if (this.user.role === 'watchman' || this.user.role === 'administrator') {
            if (lastAuth.plus({minutes: 10}) < DateTime.utc()) {

              this.authService.request2Factor().subscribe();
              const ref = this.dialog.open(CodeDialogComponent);
                ref.afterClosed().subscribe(result => {
                  this.authService.verify2Factor(result).subscribe(authState => {
                      this.snackBar.open('Enabling dispenser');
                      this.siteService.sendPumpEnable(this.siteId, $event.pumpId);
                  });
                });

                return;
            }

            this.snackBar.open('Enabling dispenser');
            this.siteService.sendPumpEnable(this.siteId, $event.pumpId);
            return;
          }


            this.snackBar.open('Not authorized to perform this action', '', {
              duration: 5000,
            });

          break;
        }

        case PumpEventTypes.EnableTestClicked: {
          if (this.user.role === 'service' || this.user.role === 'administrator') {
            if (lastAuth.plus({minutes: 30}) < DateTime.utc()) {

              this.authService.request2Factor().subscribe();
              const ref = this.dialog.open(CodeDialogComponent);
                ref.afterClosed().subscribe(result => {
                  this.authService.verify2Factor(result).subscribe(authState => {
                      this.snackBar.open('Enabling test');
                      this.siteService.sendPumpTest(this.siteId, $event.pumpId);
                  });
                });

                return;
            }

            this.snackBar.open('Enabling test');
            this.siteService.sendPumpTest(this.siteId, $event.pumpId);
            return;
          }


          this.snackBar.open('Not authorized to perform this action', '', {
              duration: 5000,
            });


          break;
        }

        case PumpEventTypes.DisableTestClicked: {
          if (this.user.role === 'service' || this.user.role === 'administrator') {
            if (lastAuth.plus({minutes: 30}) < DateTime.utc()) {

              this.authService.request2Factor().subscribe();
              const ref = this.dialog.open(CodeDialogComponent);
                ref.afterClosed().subscribe(result => {
                  this.authService.verify2Factor(result).subscribe(authState => {
                      this.snackBar.open('Disabling test');
                      this.siteService.sendPumpTestDisable(this.siteId, $event.pumpId);
                  });
                });

                return;
            }

            this.snackBar.open('Disabling test');
            this.siteService.sendPumpTestDisable(this.siteId, $event.pumpId);
            return;
          }


          this.snackBar.open('Not authorized to perform this action', '', {
              duration: 5000,
            });


          break;
        }
    }
  }

  handlePeriodEnd() {
    if (this.user.role === 'user') {
      this.snackBar.open('Not authorized to perform this action', '', {
        duration: 5000,
      });

      return;
    }

    const lastAuth = DateTime.fromJSDate(this.user.lastAuth.toDate());

    if (this.user.role === 'watchman' || this.user.role === 'administrator') {
      if (lastAuth.plus({minutes: 10}) < DateTime.utc()) {

        console.log(lastAuth, DateTime.utc());
        this.authService.request2Factor().subscribe();
        const ref = this.dialog.open(CodeDialogComponent);
          ref.afterClosed().subscribe(result => {
            this.authService.verify2Factor(result).subscribe(authState => {
                this.snackBar.open('Triggering period end');
                this.siteService.sendPeriodTrigger(this.siteId);
            });
          });

          return;
      }

      this.snackBar.open('Triggering period end');
      this.siteService.sendPeriodTrigger(this.siteId);
      return;
    }

    this.snackBar.open('Not authorized to perform this action', '', {
      duration: 5000,
    });
  }

  handlePriceChange() {
    if (this.user.role === 'user') {
      this.snackBar.open('Not authorized to perform this action', '', {
        duration: 5000,
      });

      return;
    }

    const lastAuth = DateTime.fromJSDate(this.user.lastAuth.toDate());

    if (this.user.role === 'watchman' || this.user.role === 'administrator') {
      if (lastAuth.plus({minutes: 10}) < DateTime.utc()) {

        this.authService.request2Factor().subscribe();
        const ref = this.dialog.open(CodeDialogComponent);
          ref.afterClosed().subscribe(result => {
            this.authService.verify2Factor(result).subscribe(authState => {

              const priceRef = this.dialog.open(SitePriceUpdateDialogComponent, {
                data: this.grades
              });

              priceRef.afterClosed().subscribe(diagResult => {
                const now = new Date();
                now.setMinutes(now.getMinutes() + 5);

                diagResult.activatePreinitPricesAt = diagResult.activatePreinitPricesAt ? diagResult.activatePreinitPricesAt[0] : now;
                diagResult.activatePreinitPricesAt = DateTime.fromJSDate(diagResult.activatePreinitPricesAt).toISO();

                diagResult.grades.forEach(grade => {
                  grade.preInitializedPrice.currentPrice = grade.preInitializedPrice.currentPrice * 1000;
                });
                this.snackBar.open('Sending price update');
                this.siteService.sendPriceUpdate(this.siteId, diagResult);
              });

              return;
            });
          });

          return;
      }

      const priceRefAuth = this.dialog.open(SitePriceUpdateDialogComponent, {
        data: this.grades
      });

      priceRefAuth.afterClosed().subscribe(diagResult => {
        const now = new Date();
        now.setMinutes(now.getMinutes() + 5);

        diagResult.activatePreinitPricesAt = diagResult.activatePreinitPricesAt ? diagResult.activatePreinitPricesAt[0] : now;
        diagResult.activatePreinitPricesAt = DateTime.fromJSDate(diagResult.activatePreinitPricesAt).toISO();

        diagResult.grades.forEach(grade => {
          grade.preInitializedPrice.currentPrice = grade.preInitializedPrice.currentPrice * 1000;
        });

        this.snackBar.open('Sending price update');
        this.siteService.sendPriceUpdate(this.siteId, diagResult);
      });

      return;
    }


    this.snackBar.open('Not authorized to perform this action', '', {
      duration: 5000,
    });
}

cancelPriceChange() {
  if (this.user.role === 'user') {
    this.snackBar.open('Not authorized to perform this action', '', {
      duration: 5000,
    });

    return;
  }

  const lastAuth = DateTime.fromJSDate(this.user.lastAuth.toDate());

  if (this.user.role === 'watchman' || this.user.role === 'administrator') {
    if (lastAuth.plus({minutes: 10}) < DateTime.utc()) {

      console.log(lastAuth, DateTime.utc());
      this.authService.request2Factor().subscribe();
      const ref = this.dialog.open(CodeDialogComponent);
        ref.afterClosed().subscribe(result => {
          this.authService.verify2Factor(result).subscribe(authState => {
              this.snackBar.open('Cancelling price update');
              this.siteService.sendPriceUpdateCancel(this.siteId);
              return;
          });
            return;
        });
        return;
    }

    this.snackBar.open('Cancelling price update');
    this.siteService.sendPriceUpdateCancel(this.siteId);
    return;
  }


  this.snackBar.open('Not authorized to perform this action', '', {
    duration: 5000,
  });
}


}
