import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { Observable, from } from 'rxjs';
import { Router } from '@angular/router';
import { take, mergeMap, map, flatMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<firebase.User>;

  constructor(
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private fns: AngularFireFunctions) {
    this.user = firebaseAuth.authState;
  }

  checkIfUserExists(email: string) {
    return this.firebaseAuth
      .auth
      .fetchSignInMethodsForEmail(email)
      .then(methods => methods.includes('password'));
  }

  checkIfUserIsAdmin(): Observable<boolean> {
    return this.user
      .pipe(
          take(1),
          mergeMap(user => from(user.getIdTokenResult())),
          map(token =>  token.claims['role'] === 'administrator')
        );
  }

  checkIfUserInRole(role): Observable<boolean> {
    return this.user
      .pipe(
          take(1),
          mergeMap(user => from(user.getIdTokenResult())),
          map(token =>  token.claims['role'] === role)
        );
  }

  getRole(): Observable<string> {
    return this.user
      .pipe(
          take(1),
          mergeMap(user => from(user.getIdTokenResult())),
          map(token =>  token.claims['role'])
        );
  }

  getUserListener() {
    return this.user.pipe(
      flatMap(user => {
        return this.db.doc(`users/${user.uid}`).valueChanges();
      })
    );
  }

  getAuthState() {
    const check = this.fns.httpsCallable('getAuthState');
    return check({});
  }

  request2Factor() {
    const request = this.fns.httpsCallable('request2Factor');
    return request({});
  }

  verify2Factor(code) {
    const verify = this.fns.httpsCallable('verify2Factor');
    return verify({code : code});
  }

  sendRecoveryMail(email) {
    this.firebaseAuth.auth.sendPasswordResetEmail(email).then(() => {
      console.log('mail sent');
    }).catch(err => {
      console.error(err);
    });
  }

  login(email: string, password: string) {
    return this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.firebaseAuth
      .auth
      .signOut();

    this.router.navigate(['/login']);
  }
}
