import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { MyFuelLibModule } from 'my-fuel-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/nav/nav.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { SiteListComponent } from './components/site-list/site-list.component';
import { SiteDetailsComponent } from './components/site-details/site-details.component';
import { SiteTransactionListComponent } from './components/site-transaction-list/site-transaction-list.component';
import { SiteGradePriceListComponent } from './components/site-grade-price-list/site-grade-price-list.component';

import localeBe from '@angular/common/locales/nl-BE';
import { registerLocaleData } from '@angular/common';
import { CodeDialogComponent } from './components/code-dialog/code-dialog.component';
import { RequestListComponent } from './components/request-list/request-list.component';
import { SitePriceUpdateDialogComponent } from './components/site-price-update-dialog/site-price-update-dialog.component';
import { UserEditDialogComponent } from './components/user-edit-dialog/user-edit-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

registerLocaleData(localeBe, 'nl-BE');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SiteListComponent,
    NavComponent,
    UserListComponent,
    SiteDetailsComponent,
    SiteTransactionListComponent,
    SiteGradePriceListComponent,
    CodeDialogComponent,
    RequestListComponent,
    SitePriceUpdateDialogComponent,
    UserEditDialogComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MyFuelLibModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
    MatChipsModule,
    MatMenuModule,
    BrowserAnimationsModule,
    Ng2FlatpickrModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [AngularFirestore],
  bootstrap: [AppComponent],
  entryComponents: [CodeDialogComponent, SitePriceUpdateDialogComponent, UserEditDialogComponent]
})
export class AppModule { }
