import { Injectable } from '@angular/core';
import { from, of, forkJoin } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

import { Site } from '../_models/site.model';
import { AuthService } from './auth.service';
import { mergeMap, map, take, filter, flatMap } from 'rxjs/operators';
import { Connector } from './../_models/connector.model';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Transaction } from 'my-fuel-lib/lib/models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private allowedSites: string[];

  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions,
    private authService: AuthService) {
    this.authService.user
    .pipe(
      mergeMap(user => from(user.getIdTokenResult())),
      map (token => token.claims['sites'])
    ).subscribe(sites => this.allowedSites = sites);
  }

  async getSites() {
    const result = of(this.allowedSites);
    const result2 = result.pipe(
      mergeMap(siteIds => forkJoin(siteIds.map(
        siteId =>
          this.db.doc<Site>(`sites/${siteId}`).get()
            .pipe(
              take(1),
              map(site => site.data())
            )
          )
        )
      ),
    ).toPromise();

    return result2;
  }

  getSiteListener(siteId: string) {
    if (!siteId) { return null; }

    return this.db.doc<Site>(`sites/${siteId}`).valueChanges();
  }

  getSiteTransactionListener(siteId: string) {
    return this.getSiteListener(siteId).pipe(
      map(site => {
        let result: Transaction[] = [];
        for (const pump of site.data.pumps) {

          if (!pump.transactions) {
            continue;
          }

          // Dirty hack to add the pump ID, fix this later
          pump.transactions.forEach(
            transaction => transaction.pumpId = pump.id
          );

          result = result.concat(pump.transactions);
        }
        return result.sort((prev, next) => {
          return new Date(next.startTime).getTime() - new Date(prev.startTime).getTime();
        });
      })
    );
  }

  async setConnected(siteId: string) {
    await this.db.doc(`connectors/${siteId}`).update({state: 'ACTIVE'});
  }

  sendPumpDisable(siteId: string, pumpId) {
    const updateRequests = this.fns.httpsCallable('pumpDisable');
    updateRequests({site: siteId, pumpId: pumpId})
      .pipe(take(1))
      .subscribe();
  }

  sendPumpEnable(siteId: string, pumpId) {
    const updateRequests = this.fns.httpsCallable('pumpEnable');
    updateRequests({site: siteId, pumpId: pumpId})
      .pipe(take(1))
      .subscribe();
  }

  sendPumpTest(siteId: string, pumpId) {
    const updateRequests = this.fns.httpsCallable('pumpTest');
    updateRequests({site: siteId, pumpId: pumpId})
      .pipe(take(1))
      .subscribe();
  }

  sendPumpTestDisable(siteId: string, pumpId) {
    const updateRequests = this.fns.httpsCallable('pumpTestDisable');
    updateRequests({site: siteId, pumpId: pumpId})
      .pipe(take(1))
      .subscribe();
  }

  sendPeriodTrigger(siteId: string) {
    const updateRequests = this.fns.httpsCallable('periodTrigger');
    updateRequests({site: siteId})
      .pipe(take(1))
      .subscribe();
  }

  sendPriceUpdate(siteId: string, priceUpdate) {
    const updateRequests = this.fns.httpsCallable('priceUpdate');
    updateRequests({site: siteId, priceUpdate: priceUpdate})
      .pipe(take(1))
      .subscribe();
  }

  sendPriceUpdateCancel(siteId: string) {
    const updateRequests = this.fns.httpsCallable('priceUpdateCancel');
    updateRequests({site: siteId})
      .pipe(take(1))
      .subscribe();
  }

  getConnectorListener(siteId: string) {
    if (!siteId) { return null; }

    return this.db.doc<Connector>(`connectors/${siteId}`).valueChanges();
  }
}
