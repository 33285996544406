import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from './../../_services/user.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, FormControlName } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {

  public userForm: FormGroup;

  public title = 'Add user';

  public groups;
  public roles = ['administrator', 'service', 'watchman', 'user'];
  public states = ['active', 'disabled'];

  constructor(private userService: UserService, @Inject(MAT_DIALOG_DATA) public data) {
    if (data) {
      this.userForm = new FormGroup({
        id: new FormControl(data.id, [Validators.required]),
        email: new FormControl(data.email, [Validators.required, Validators.email]),
        name: new FormControl(data.name, [Validators.required]),
        phoneNumber: new FormControl(data.phoneNumber, [Validators.required]),
        role: new FormControl(data.role, [Validators.required]),
        // @ts-ignore
        group: new FormControl(data.group.id, [Validators.required]),
        state: new FormControl(data.state, [Validators.required])
      });

      this.userService.getGroups().pipe(
        take(1)
      ).subscribe(groups => this.groups = groups);

      return;
    }

    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      role: new FormControl('user', [Validators.required]),
      group: new FormControl('', [Validators.required]),
      state: new FormControl('active', [Validators.required])
    });

    this.userService.getGroups().pipe(
      take(1)
    ).subscribe(groups => {
      this.groups = groups;
      this.userForm.patchValue({ group: this.groups[0].id});
    });

  }

  ngOnInit() {
  }

}
