import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { SiteListComponent } from './components/site-list/site-list.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { AdminAuthGuard } from './_guards/admin.guard';
import { NavComponent } from './components/nav/nav.component';
import { SiteDetailsComponent } from './components/site-details/site-details.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sites',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: SiteListComponent },
      { path: '', component: NavComponent, outlet: 'nav' },
      { path: ':id', component: SiteDetailsComponent }
    ]
  },
  {
    path: 'users',
    canActivate: [AuthGuard, AdminAuthGuard],
    children: [
      { path: '', component: UserListComponent },
      { path: '', component: NavComponent, outlet: 'nav' },
    ]
  },
  {
    path: '**',
    redirectTo: 'sites'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
