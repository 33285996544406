export enum PumpEventTypes {
  EnableClicked,
  DisableClicked,
  EnableTestClicked,
  DisableTestClicked,
}

export class PumpEvent {
  pumpId: number;
  type: PumpEventTypes;
}
