import { Component, OnInit } from '@angular/core';
import { UserService } from './../../_services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  public users;
  public groups;
  public userColumnsToDisplay = ['mail', 'name', 'role', 'group'];
  public groupColumnsToDisplay = ['name', 'sites'];

  constructor(private userService: UserService, public dialog: MatDialog) {
    this.users = this.userService.getUsers();
    this.groups = this.userService.getGroups();
  }

  ngOnInit() {
  }

  handleNew() {
    const ref = this.dialog.open(UserEditDialogComponent);
    ref.afterClosed().subscribe(user => {
      if (user) {
        this.userService.addUser(user);
      }
    });
  }

  handleEdit(user) {
    const ref = this.dialog.open(UserEditDialogComponent, {data: user});
    ref.afterClosed().subscribe(userUpd => {
      if (userUpd) {
        this.userService.updateUser(userUpd);
      }
    });
  }

}
