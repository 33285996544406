import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../_services/site.service';
import { Site } from 'src/app/_models/site.model';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';


@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {

  public sites;
  public search = new FormControl('');
  public page = 'sites';
  public admin;
  public loading =  true;

  constructor(private siteService: SiteService, public router: Router, private authService: AuthService) {
  }

  async ngOnInit() {
    this.sites = await this.siteService.getSites();

    this.sites = this.sites.filter(site => site.active);
    this.sites.sort((a, b) => {
      const firstNumber = a.name.substr(a.length - 4);
      const secondNumber = b.name.substr(a.length - 4);
      if (firstNumber < secondNumber) { return -1; }
      if (firstNumber > secondNumber) { return 1; }
      return 0;
    });

    this.loading = false;
    this.admin = this.authService.checkIfUserIsAdmin();
  }

  filter(site: Site) {

    const value = this.search.value.toLocaleLowerCase();

    if (!value) { return true; }

    if (site.name.toLocaleLowerCase().includes(value) || site.location.toLocaleLowerCase().includes(value)) { return true; }

    return false;
  }

  handleClick(site: Site) {
    this.router.navigate([`sites/${site.id}`]);
  }

  navigateTo(page) {
    this.page = page;
  }

}
