import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Site } from 'src/app/_models/site.model';
import { take } from 'rxjs/operators';
import { Grade } from 'my-fuel-lib/lib/models/grade.model';
import { FormControl } from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-site-price-update-dialog',
  templateUrl: './site-price-update-dialog.component.html',
  styleUrls: ['./site-price-update-dialog.component.scss']
})
export class SitePriceUpdateDialogComponent implements OnInit {
  public result;

  dateTimeOptions: FlatpickrOptions = {
    minDate: 'today',
    enableTime: true,
    dateFormat: 'Y-m-d H:i',
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: Grade[]) {
    this.result = {
      grades: [...data]
    };

    const now = new Date();
    now.setMinutes(now.getMinutes() + 5);
    this.dateTimeOptions.defaultDate = now;
    this.result.activatePreinitPricesAt = [now];

    this.result.grades.forEach(grade => {
      grade.preInitializedPrice.currentPrice = grade.activePrice.currentPrice / 1000;
    });
  }

  ngOnInit() {
  }

}
