import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from 'src/app/_services/request.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent implements OnInit, OnDestroy {

  public requests;
  private requestSub: Subscription;
  public columnsToDisplay = ['timestamp', 'site', 'user', 'type'];

  dataSource: MatTableDataSource<Request>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public requestService: RequestService) {
    this.requestSub = requestService.getRequestListener().subscribe(requests => {
      this.requests = requests;
      this.dataSource = new MatTableDataSource(this.requests);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.requestSub.unsubscribe();
  }

}
