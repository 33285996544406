import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private db: AngularFirestore) { }


  getRequestListener() {
    return this.db.collection('requests').valueChanges().pipe(
      map(requests => {
        return requests.map(request => {
          // @ts-ignore
          request.timestamp = request.timestamp.toDate();
          // @ts-ignore
          request.site.get().then(site => {
            // @ts-ignore
            request.site = site.data();
          });

          // @ts-ignore
          request.user.get().then(user => {
            // @ts-ignore
            request.user = user.data();
          });
          return request;
        });
      })
    );
  }
}
