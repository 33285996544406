import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required]);

  public mode = 'login';

  constructor(public authService: AuthService,
    private router: Router,
    public snackBar: MatSnackBar) {

    if (this.authService.user) {
    this.router.navigate(['/']);
    }
  }

  ngOnInit() {
  }

  checkLogin() {
    this.authService.checkIfUserExists(this.email.value)
    .then(val => {
      if (!val) {
        throw new Error('Unknown email');
      }

      this.authService.login(this.email.value, this.password.value)
      .then(() => this.router.navigateByUrl('/'))
      .catch((err) => {
        this.password.setErrors({password: true});
      });
    })
    .catch(err => {
      this.email.setErrors({email: true});
    });
    }

  switchResetPass() {
    if (this.mode === 'login') { this.mode = 'reset'; return; }

    this.mode = 'login';

  }

  sendRecovery() {
    this.authService.sendRecoveryMail(this.email.value);
    this.mode = 'login';
    this.snackBar.open('Recovery email has been sent.', 'OK', {
      duration: 2000,
    });
  }

}
